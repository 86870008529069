import type { SafeEventEmitterProvider } from "@web3auth/base";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import {
  signTypedData,
  SignTypedDataVersion,
  TypedMessage,
} from "@metamask/eth-sig-util";

export default class EthereumRpc {
  private provider: SafeEventEmitterProvider;

  constructor(provider: SafeEventEmitterProvider) {
    this.provider = provider;
  }

  async getChainId(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get the connected Chain's ID
      const chainId = await web3.eth.getChainId();

      return chainId.toString();
    } catch (error) {
      return error as string;
    }
  }

  async getAccounts(): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      return address;
    } catch (error) {
      return error;
    }
  }

  async getBalance(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      const balanceOfABI = [
        {
          constant: true,
          inputs: [
            {
              name: "_owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              name: "balance",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
      ];

      const tokenContract = "0x65d3038cDf0F1a2aaC7BF756f0Cc422A765651de";

      const contract = new web3.eth.Contract(
        balanceOfABI as AbiItem[],
        tokenContract
      );

      const result = await contract.methods.balanceOf(address).call();

      return (parseInt(result) / 1000000).toFixed(2);
    } catch (error) {
      return error as string;
    }
  }

  async getTransferPayload(toAddress: string): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any);
      const chainId = 80001;
      const chainIdHex = web3.utils.toHex(chainId);
      const paddedChainId = web3.utils.padLeft(chainIdHex, 64).slice(2);
      const value = 10000000;
      const validBefore = Math.floor(Date.now() / 1000) + 3600;
      const validAfter = 0;
      const nonce = Web3.utils.randomHex(32);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const data = {
        types: {
          EIP712Domain: [
            { type: "string", name: "name" },
            { type: "string", name: "version" },
            { type: "address", name: "verifyingContract" },
            { type: "bytes32", name: "salt" },
          ],
          TransferWithAuthorization: [
            { name: "from", type: "address" },
            { name: "to", type: "address" },
            { name: "value", type: "uint256" },
            { name: "validAfter", type: "uint256" },
            { name: "validBefore", type: "uint256" },
            { name: "nonce", type: "bytes32" },
          ],
        },
        domain: {
          name: "Sinbad USD",
          version: "1",
          verifyingContract: "0x65d3038cdf0f1a2aac7bf756f0cc422a765651de",
          salt: Buffer.from(paddedChainId, "hex"),
        },
        primaryType: "TransferWithAuthorization",
        message: {
          from: fromAddress,
          to: toAddress,
          value: value,
          validAfter: validAfter,
          validBefore: validBefore,
          nonce: nonce,
        },
      };

      const pk: string = await this.getPrivateKey();

      const signature = signTypedData({
        privateKey: Buffer.from(pk, "hex"),
        data: data as TypedMessage<any>,
        version: SignTypedDataVersion.V3,
      });

      const v = "0x" + signature.slice(130, 132);
      const r = signature.slice(0, 66);
      const s = "0x" + signature.slice(66, 130);

      return {
        from: fromAddress,
        to: toAddress,
        value: value,
        validAfter: validAfter,
        validBefore: validBefore,
        nonce: nonce,
        v,
        r,
        s,
      };
    } catch (error) {
      return error as string;
    }
  }

  async signMessage() {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const originalMessage = "YOUR_MESSAGE";

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        originalMessage,
        fromAddress,
        "test password!" // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error as string;
    }
  }

  async getPrivateKey(): Promise<any> {
    try {
      const privateKey = await this.provider.request({
        method: "eth_private_key",
      });

      return privateKey;
    } catch (error) {
      return error as string;
    }
  }
}
